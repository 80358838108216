<template>
  <v-container
    v-if="userData && userData.fidelityCard && userData.fidelityCard.length > 0"
  >
    <h4>{{ $t("dashboard.greetingPrefix") }}{{ userData.firstName }}</h4>
    <p>
      {{ $t("profile.fidelity.alreadyHasACard") }} - {{ userData.fidelityCard }}
    </p>
    <v-btn
      large
      depressed
      outlined
      color="primary"
      class="rounded-pill"
      :to="{ name: 'Dashboard' }"
    >
      {{ $t("profile.fidelity.goToDashboard") }}
    </v-btn>
  </v-container>
  <v-container
    class="profile-update-container scroll-container"
    v-else-if="userData"
  >
    <CategoryTitle :category="category" class="mb-3" :addContainer="false" />

    <p
      class="text-uppercase text-h4 font-weight-bold"
      v-html="$t('profile.update.personalData')"
    />
    <!-- PERSONAL DATA -->
    <UserData
      ref="personalDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :askFirstName="true"
      :askLastName="true"
      :askFiscalCode="false"
      :isProfileForm="true"
      :showButtons="false"
      :showFiscalCode="false"
      :isFieldDisabled="false"
      @validate="validate"
    />
    <!-- <UserAddress
      ref="addressDataForm"
      :userData.sync="userData"
      :supportData.sync="supportData"
      :isFieldDisabled="true"
      :isProfileForm="true"
      :showButtons="false"
    /> -->
    <div class="py-3">* {{ $t("profile.requiredFields") }}</div>

    <p
      class="text-uppercase text-h4 font-weight-bold"
      v-html="$t('profile.fidelity.policy.title')"
    />
    <p v-html="$t('profile.fidelity.policy.description')"></p>
    <v-checkbox
      v-model="fidelityCardAgreement"
      class="disclaimer-checkbox"
      color="primary"
      required
    >
      <template v-slot:label>
        <span
          class="disclaimer-label disclaimer-checkbox-label"
          v-html="$t('profile.fidelity.policy.checkboxLabel')"
        ></span>
      </template>
    </v-checkbox>

    <p
      class="text-uppercase text-h4 font-weight-bold"
      v-html="$t('profile.privacy.title')"
    />

    <p class="text-uppercase" v-html="$t('profile.privacy.consent1')"></p>
    <v-radio-group
      required
      :column="false"
      v-model="consent1"
      @change="updatePersonInfo('101', $event)"
    >
      <v-radio :value="1" class="mr-3">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.agree") }}</div>
        </template>
      </v-radio>

      <v-radio :value="0">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.notAgree") }}</div>
        </template>
      </v-radio>
    </v-radio-group>

    <p class="text-uppercase" v-html="$t('profile.privacy.consent2')"></p>
    <v-radio-group
      required
      :column="false"
      v-model="consent2"
      @change="updatePersonInfo('102', $event)"
    >
      <v-radio :value="1" class="mr-3">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.agree") }}</div>
        </template>
      </v-radio>

      <v-radio :value="0">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.notAgree") }}</div>
        </template>
      </v-radio>
    </v-radio-group>

    <p class="text-uppercase" v-html="$t('profile.privacy.consent3')"></p>
    <v-radio-group
      required
      :column="false"
      v-model="consent3"
      @change="updatePersonInfo('103', $event)"
    >
      <v-radio :value="1" class="mr-3">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.agree") }}</div>
        </template>
      </v-radio>

      <v-radio :value="0">
        <template v-slot:label>
          <div>{{ $t("profile.privacy.notAgree") }}</div>
        </template>
      </v-radio>
    </v-radio-group>

    <!-- UPDATE PERSONAL DATA -->
    <v-col class="pa-0 ml-auto" cols="12">
      <div class="d-flex justify-end py-1">
        <v-btn
          color="primary"
          class="rounded-pill"
          large
          depressed
          :disabled="!isValidForm"
          v-html="$t('profile.askFidelityCard')"
          @click="validate"
        />
      </div>
    </v-col>
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("profile.fidelity.dialog.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("profile.fidelity.dialog.description") }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style lang="scss">
.profile-update-container {
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserData from "@/components/profile/form/UserData.vue";

import categoryMixins from "~/mixins/category";

import { getPersonInfo } from "~/service/ebsn";
import UserService from "~/service/userService";
import StoreService from "~/service/storeService";
import CustomService from "@/service/customService";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { mapActions, mapState } from "vuex";
import {
  requiredValue,
  isEmail,
  isNumber,
  minLength,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

export default {
  name: "ProfileUpdateInternal",
  components: {
    CategoryTitle,
    UserData
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      isValid: true,
      showPassword: false,
      oldPassword: null,
      loadingPasswordChange: false,
      password: null,
      disclaimerList: null,
      userDisclaimer: null,
      registrationModules: null,
      isDisclaimerFormValid: false,
      loadingDisclaimerChange: false,
      disclaimerToChange: null,
      disclaimerOptions: {
        color: "primary"
      },
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.newEmail || "L'e-mail non coincidono"
      ],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: {
        person: {},
        contact: {}
      },
      supportData: {
        privacy1: true,
        privacy2: true,
        country: {
          itemId: null
        }
      },
      billingAddress: {},
      provinces: [],
      response: {},
      loadingAnonymizeAccount: false,
      removeDialog: false,
      responseAnonymazeAccount: {},
      isPasswordFormValid: false,
      isEmailFormValid: false,
      newEmail: null,
      emailConfirm: null,
      loadingEmailChange: false,
      selectedStore: null,
      storeList: [],
      fidelityCardAgreement: false,
      dialog: false,
      consent1: null,
      consent2: null,
      consent3: null
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    isValidForm() {
      return (
        this.fidelityCardAgreement &&
        this.userData.person.fiscalCode.length > 0 &&
        this.userData.person.gender != "" &&
        this.consent1 != null &&
        this.consent1 != null &&
        this.consent1 != null
      );
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      loadCart: "cart/loadCart"
    }),
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    updatePersonInfo(personInfoTypeId, newValue) {
      const info = this.userData.person.personInfos.find(
        info => info.personInfoTypeId === personInfoTypeId
      );

      if (info) {
        // If found, update its value
        info.value = newValue;
      } else {
        // If not found, add a new object to the array
        this.userData.person.personInfos.push({
          personInfoTypeId,
          value: newValue
        });
      }
    },
    async getUserData() {
      let stores = await this.fetchStoreList();
      const result = await UserService.getUserDetail();
      if (result) {
        this.userData = result;
        if (getPersonInfo(this.userData.person, 1) == "1") {
          this.storeList.push(stores[0]);
        }
        if (getPersonInfo(this.userData.person, 4) == "1") {
          this.storeList.push(stores[1]);
        }
        if (getPersonInfo(this.userData.person, 7) == "1") {
          this.storeList.push(stores[2]);
        }
        if (this.storeList.length > 0) {
          this.selectedStore = this.cart.store.storeId;
        }
      }
    },
    async validate() {
      this.dialog = true;
      let personalData = this.$refs.personalDataForm.$refs.form;
      var resCardAssoc = null;
      if (personalData.validate()) {
        resCardAssoc = await CustomService.associateCard(this.userData);
      }

      this.dialog = false;

      if (
        resCardAssoc &&
        resCardAssoc.response.status === 0 &&
        resCardAssoc.data.user?.fidelityCard.length > 0
      ) {
        await this.updateProfile(resCardAssoc.data.user);

        this.$router.push("/profile/dashboard");
      }
    },
    back() {
      this.$router.back();
    },
    async updateProfile(user) {
      UserService.updateUserDetail(user).then(() => {
        this.loadCart();
      });
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await CustomService.updatePassword(_this.password);
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },
    async updateEmail() {
      try {
        this.response = {};
        this.loadingEmailChange = true;
        if (this.$refs.emailform.validate()) {
          this.userData.email = this.newEmail;
          let res = await CustomService.updateEmailRequest(this.userData);
          this.loadingEmailChange = false;
          if (res) {
            this.loadCart();
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loadingEmailChange = false;
      }
    },

    async anonymizeAccount(perfom = false) {
      this.removeDialog = true;
      if (perfom) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          this.removeDialog = false;
          // se utente davvero anonimizzato -> logout
          await this.doLogout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({ name: "Home" });
        } else {
          this.responseAnonymazeAccount = result.response;
          this.removeDialog = false;
          this.loadingAnonymizeAccount = false;
        }
      }
    },
    async fetchStoreList() {
      let data = await StoreService.getStores();
      return data.stores;
    }
  },
  async mounted() {
    await this.getUserData();
    setTimeout(() => {
      document.querySelector(".vuedl-layout__closeBtn").click();
    }, 1500);
    //await this.loadDisclaimer();
    //await userService.getUserDisclaimerListByType();
    //await this.getUserDisclaimer();
  }
};
</script>
